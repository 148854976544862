import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Row, Col, FormCheck } from 'react-bootstrap';
import { ReactSelect } from "@services/ui-components";
import {Formik, Field, FieldArray, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const initialValues = {
    groupName: '',
    dish: '',
    additions: [
        { name: '', price: '' }
    ],
    enableChoice: false, // Для чекбокса "Обов'язково"
    maxChoice: 1, // Для поля "Макс. кількість для вибору"
};

const validationSchema = Yup.object().shape({
    groupName: Yup.string().required('Назва групи обов\'язкова'),
    dish: Yup.string().required('Страву необхідно обрати'),
    additions: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Назва добавки обов\'язкова'),
            price: Yup.number().required('Ціна добавки обов\'язкова').min(0, 'Ціна не може бути від\'ємною')
        })
    ),
    enableChoice: Yup.boolean(),
    maxChoice: Yup.number().min(1, 'Мінімальне значення - 1')
});

const DialogCreateGroupSupplements = ({ show, onHide }: any) => {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="fs-5">{t("New additives group")}</Modal.Title>
                {/* When main language will be added*/}
                <div className="text-primary bg-violet-light rounded-1 fs-7 p-1 px-6 ms-3">Українська</div>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    console.log(values);
                    onHide();
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <div className="px-6">
                                <Form.Group className="mb-4">
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Label htmlFor="createSupplements-name-field" className="fs-7 fw-bold text-start mb-0">{t("Additive group name")}
                                                <span className="text-danger ms-1">*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <Field id="createSupplements-name-field" name="groupName" as={Form.Control} placeholder={t("Add additive group name")} />
                                            <ErrorMessage name="name">{msg => <p className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Row className="align-items-center">
                                        <Col sm={4}>
                                            <Form.Label htmlFor="createSupplements-dish-field" className="fs-7 fw-bold text-start mb-0">{t("Dishes")}</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <ReactSelect
                                                id="createSupplements-dish-field"
                                                name="dish"
                                                value={null}
                                                options={[]}
                                                placeholder={t("Select dishes")}
                                                onChange={() => {}}
                                                isSearchable={true}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <h5 className="fs-16 mb-3">{t("List of additive/option variants")}
                                    <span className="text-danger ms-1">*</span>
                                </h5>
                                <FieldArray name="additions">
                                    {({ push, remove }) => (
                                        <>
                                            {values.additions.map((addition, index) => (
                                                <div key={index} className="mb-3">
                                                    <Row className="mb-3">
                                                        <Col sm={4}>
                                                            <Form.Label htmlFor="createSupplements-option-field" className="fs-7 fw-bold text-start mb-0">{t("Additive/ option name")}</Form.Label>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <Field id="createSupplements-option-field" name={`additions[${index}].name`} as={Form.Control} placeholder={t("Add name")} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col sm={4}>
                                                            <Form.Label htmlFor="createSupplements-price-field" className="fs-7 fw-bold text-start mb-0">{t("Option price")}</Form.Label>
                                                        </Col>
                                                        <Col sm={8} className="d-flex">
                                                            <div className="position-relative w-100">
                                                                <Field id="createSupplements-price-field" className="pe-5" name={`additions[${index}].price`} as={Form.Control} placeholder={t("Option price")} type="number" />
                                                                {/*Add currency company var*/}
                                                                <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">$</div>
                                                            </div>
                                                            {values.additions.length > 1 && index >= 1 && (
                                                                <div className="ms-2">
                                                                    <Button variant="outline-secondary" className="w-100 px-3 py-6 fs-normal rounded-1 align-self-start" onClick={() => remove(index)}>
                                                                        <i className="bi bi-trash3 text-dark"></i>
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Col sm={8} className="ms-auto ps-2">
                                                <Button type="button" variant="light"
                                                        className="fs-7 text-primary p-0 border-0 bg-transparent" onClick={() => push({ name: '', price: '' })}>
                                                    <i className="bi bi-plus me-1"></i>
                                                    {t("Add another additive variant or option")}
                                                </Button>
                                            </Col>
                                        </>
                                    )}
                                </FieldArray>
                                <Form.Group className="mb-3 mt-4">
                                    <Row className="align-items-center">
                                        <Col sm={4}>
                                            <Form.Label htmlFor="createSupplements-enableChoice-field" className="fs-7 fw-bold text-start mb-0">{t("Selection when ordering")}</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <div className="form-check form-switch">
                                                <FormCheck
                                                    type="checkbox"
                                                    id="createSupplements-enableChoice-field"
                                                    label={
                                                        values.enableChoice ? t('Mandatory') : t('Optional')
                                                    }
                                                    name="enableChoice"
                                                    className="fs-16 ps-0"
                                                    checked={values.enableChoice}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Label htmlFor="createSupplements-maxChoice-field" className="fs-7 fw-bold text-start mb-0">{t("Max selection quantity")}</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <Field id="createSupplements-maxChoice-field" name="maxChoice" as={Form.Control} type="number" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-between border-top-0">
                            <Button variant="outline-primary" className="custom-btn" onClick={onHide}>{t("cancel")}</Button>
                            <Button type="submit" variant="primary" className="custom-btn fw-bold">{t("Save")}</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default DialogCreateGroupSupplements;
