import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Branch, CreateBranch, CreateMenuItem, CurrentBranch, MainMenu, MenuItems, CurrentMenuItem, ClientsHistoryVisit } from '../components';
import CategoriesTable from '../components/CategoriesTable/CategoriesTable';
import AdditivesTable from '../components/AdditivesTable/AdditivesTable';
import MenuPage from '../components/MenuPageTable/MenuPage';
import EmployeesList from '../components/EmployeesList/EmployeesList';
import AddEmployee from '../components/AddEmployee/AddEmployee';
import EditingEmployee from '../components/EditingEmployee/EditingEmployee';
import Positions from '../components/Positions/Positions';
import { ServiceCategories } from '../components/Services/ServiceCategories';

const commonRoutes = [
    {
        path: "branches",
        element: <Branch />,
    },
    {
        path: "create-branch",
        element: <CreateBranch />,
    },
    {
        path: "branch/:id",
        element: <CurrentBranch />,
    },
    {
        path: "branch/menu/:id",
        element: <MainMenu />,
    },
    {
        path: "branch/menu/items",
        element: <MenuItems />,
    },
    {
        path: "clients-history-visit",
        element: <ClientsHistoryVisit />,
    },
    {
        path: "branch/menu/create-item",
        element: <CreateMenuItem />,
    },
    {
        path: "branch/menu/item/:id",
        element: <CurrentMenuItem />,
    },
    {
        path: "category",
        element: <CategoriesTable />,
    },
    {
        path: "additives",
        element: <AdditivesTable />,
    },
    {
        path: "menu/page",
        element: <MenuPage />,
    },
    {
        path: "menu/page/:id",
        element: <MenuPage />,
    },
    {
        path: 'employees-list',
        element: <EmployeesList />,
    },
    {
        path: 'employees-list/:position',
        element: <EmployeesList />,
    },
    {
        path: 'employees-list/employee/:userId',
        element: <EmployeesList />,
    },
    {
        path: 'employees-list/add-employee',
        element: <AddEmployee />
    },
    {
        path: 'employees-list/edit-employee/:id',
        element: <EditingEmployee />,
    },
    {
        path: 'positions',
        element: <Positions />,
    },
    {
        path: "service-categories",
        element: <ServiceCategories />,
    },

]

export const router = createBrowserRouter(commonRoutes);

export default commonRoutes
