import React, { useState } from 'react';
import {Link} from "react-router-dom";
import { Table, Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {FiltersButton, Pagination, ReactSelect, TableHeader} from '@services/ui-components';
import {
    GetMenuCategoriesQueryVariables,
    useGetMenuCategoriesQuery,
} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import MoveCategoryModal from './MoveCategoryModal';
import CategoryFormModal from './CategoryFormModal';
import DeleteCategoryModal from './DeleteCategoryModal';

interface ExpandedCategoriesState {
    [key: number]: boolean;
}

const CategoriesTable: React.FC = () => {
    const { t } = useTranslation();
    const basePath = process.env.REACT_APP_POS_URL;
    const [expandedCategories, setExpandedCategories] = useState<ExpandedCategoriesState>({});
    const [showFilters, setShowFilters] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({ name: '', branchTypeId: '' });
    const [filter, setFilter] = useState({ name: '', branchTypeId: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const toggleFilters = () => setShowFilters(!showFilters);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [categoryToDelete, setCategoryToDelete] = useState<any>(null);

    const toggleExpandCategory = (categoryId: number) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId],
        }));
    };

    const { data: categoriesData, loading, error, refetch } = useGetMenuCategoriesQuery({
        variables: {
            id: null,
            search: filter.name || null,
            page: currentPage,
            itemsPerPage,
            isDeleted: null,
        } as GetMenuCategoriesQueryVariables,
    });

    const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(null); // For editing/restoring
    const [parentCategory, setParentCategory] = useState<any>(null); // For creating subcategories

    const [showMoveCategoryModal, setShowMoveCategoryModal] = useState<boolean>(false);
    const [categoryToMove, setCategoryToMove] = useState<any>(null);

    const handleArchiveCategory = async (category: any) => {
        // Instead of directly archiving, open the delete modal
        setCategoryToDelete(category);
        setShowDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        setShowDeleteModal(false);
        refetch();
    };

    const handleEditCategory = (category: any) => {
        setSelectedCategory(category);
        setParentCategory(category.parent || null);
        setShowCategoryModal(true);
    };

    const handleAddSubcategory = (category: any) => {
        setSelectedCategory(null);
        setParentCategory(category);
        setShowCategoryModal(true);
    };

    const handleMoveCategory = (category: any) => {
        setCategoryToMove(category);
        setShowMoveCategoryModal(true);
    };

    const handleRestoreCategory = (category: any) => {
        setSelectedCategory(category);
        setParentCategory(category.parent || null);
        setShowCategoryModal(true);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleApplyFilter = () => {
        setFilter(currentFilter);
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        setFilter({ name: '', branchTypeId: '' });
        setCurrentFilter({ name: '', branchTypeId: '' });
        setCurrentPage(1);
    };

    if (loading) return <div>{t('Loading...')}</div>;
    if (error) return <div>{t('An error occurred:')} {error.message}</div>;

    const categories = categoriesData?.user?.company?.meMenuCategoriesPaginated?.categories || [];
    const pageCount = parseInt(categoriesData?.user?.company?.meMenuCategoriesPaginated?.pageCount || '0');
    const totalItems = categoriesData?.user?.company?.meMenuCategoriesPaginated?.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleApplyFilter();
        }
    };

    return (
        <div className="mb-4">
            <TableHeader
                title={t("Category of dishes")}
                paths={[{ path: "/branches", label: t("Branches") }, { path: "/category", label: t('Category of dishes') }]}
                buttons={
                    <Row className="gx-3 justify-content-end mt-3 mt-lg-0">
                        <Col xs={12} sm={5} xxl={4} className="mb-3 mb-lg-0">
                            <div className="position-relative">
                                <span className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">{t("Language version")}</span>
                                <ReactSelect
                                    id="listOfDishes-language-field"
                                    value={{value: 'Українська (основна)', label: 'Українська (основна)'}}
                                    options={[]}
                                    placeholder={t("Type of branch")}
                                    onChange={() => {}}
                                    isSearchable={true}
                                    disabled={false}
                                    isMeMenu={true}
                                    isTitle={true}
                                />
                            </div>
                        </Col>
                        <Col xs={10} sm={5} xxl={4} className="mb-3 mb-lg-0">
                            <Button
                                variant="primary"
                                className="fw-normal w-100 rounded-1 fw-bold text-truncate"
                                onClick={() => {
                                    setSelectedCategory(null);
                                    setParentCategory(null);
                                    setShowCategoryModal(true);
                                }}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                {t("Add Category")}
                            </Button>
                        </Col>
                        <Col xs={2}>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters} classesText="d-none d-xxl-block"/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={5}
                btnColSize={7}
            >
                <Row className="gx-4 pb-6">
                    <Col lg={8} xl={9}>
                        <Row className="gx-3">
                            <Col md={12}>
                                <div className="position-relative mb-3 mb-lg-0">
                                    <input
                                        type="text"
                                        className="form-control rounded-1 pe-5 text-truncate"
                                        placeholder={t("Search by name")}
                                        value={currentFilter.name}
                                        onChange={(e) => setCurrentFilter({ ...currentFilter, name: e.target.value })}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                    <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3"></i>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} xl={3}>
                        <Row className="gx-3 h-100">
                            <Col xs={6}>
                                <Button
                                    variant="outline-dark"
                                    className="border-grey w-100 h-100 fw-normal rounded-1"
                                    onClick={handleClearFilter}
                                >
                                    {t("clear")}
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    variant="primary"
                                    className="w-100 h-100 rounded-1 fw-normal px-2"
                                    onClick={handleApplyFilter}
                                >
                                    {t("apply")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader>
            <Table responsive>
                <thead>
                <tr className="fs-7">
                    <th className="col-3 px-4 text-nowrap">{t('Category / Subcategory')}</th>
                    <th className="col-4 table-thead-width">{t('description')}</th>
                    <th className="col-1">{t('Icon')}</th>
                    <th className="col-1">{t('Amount of dishes')}</th>
                    <th className="col-3"></th>
                </tr>
                </thead>
                <tbody>
                {categories.map((category) => (
                    <React.Fragment key={category.id}>
                        {/* Main Category Row */}
                        <tr>
                            <td className={`px-4 align-middle ${category.deleted ? 'text-secondary' : 'text-dark'}`}>
                                <Button variant="link" className={`p-0 ${category.deleted ? 'text-secondary' : 'text-dark'}`} onClick={() => toggleExpandCategory(category.id)}>
                                    {category.childCategories.length !== 0 ? (
                                        expandedCategories[category.id] ? (
                                            <i className="bi bi-chevron-up me-2"></i>
                                        ) : (
                                            <i className="bi bi-chevron-down me-2"></i>
                                        )
                                    ) : null}
                                </Button>
                                {' '}{category.name}
                            </td>
                            <td>
                                <span className={category?.deleted ? 'text-secondary' : 'text-dark'}>{category.comment || "—"}</span>
                            </td>
                            <td className="align-middle">
                                <div className="position-relative" style={{ width: "30px", height: "30px" }}>
                                    {category?.favicon ? (
                                        <img src={`${basePath}/${category.favicon}`} alt={category.name}/>
                                    ) : null}
                                    {category.deleted ? <div className="position-absolute top-0 left-0 w-100 h-100 opacity-50"></div> : null}
                                </div>
                            </td>
                            <td className={category?.deleted ? 'text-secondary' : 'text-dark'}>
                                {category.numberOfItems !== 0 ?
                                    <Link to={"#"} className={`text-decoration-none ${category?.deleted ? 'text-secondary' : 'text-primary'}`}>{category.numberOfItems}</Link>
                                    : category.numberOfItems}
                            </td>
                            <td>
                                <div className="d-flex gap-2 justify-content-end align-items-center">
                                    {!category.deleted && (
                                        <>
                                            {/* Move Category Button */}
                                            {category.childCategories.length === 0 && (
                                                <OverlayTrigger overlay={<Tooltip>{t('Move Category')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="py-2"
                                                        onClick={() => handleMoveCategory(category)}
                                                    >
                                                        <i className="bi bi-box-arrow-right text-dark"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            )}
                                            {/* Create Subcategory Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Create Subcategory')}</Tooltip>}>
                                                <Button
                                                    variant="outline-secondary"
                                                    className="py-2"
                                                    onClick={() => handleAddSubcategory(category)}
                                                >
                                                    <i className="bi bi-plus-lg text-dark"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            {/* Edit Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                <Button
                                                    variant="outline-secondary"
                                                    className="py-2"
                                                    onClick={() => handleEditCategory(category)}
                                                >
                                                    <i className="bi bi-pencil text-dark"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            {/* Archive Button */}
                                            <OverlayTrigger overlay={<Tooltip>{t('Delete')}</Tooltip>}>
                                                <Button
                                                    variant="outline-secondary"
                                                    className="py-2"
                                                    onClick={() => handleArchiveCategory(category)}
                                                >
                                                    <i className="bi bi-trash text-dark"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                    {category.deleted && (
                                        <>
                                            {/* Restore Button */}
                                            <span className="text-grey fs-7 px-3 py-2 fw-normal rounded-1 bg-additional-grey">{t('Archive')}</span>
                                            <OverlayTrigger overlay={<Tooltip>{t('Restore')}</Tooltip>}>
                                                <Button
                                                    variant="outline-secondary"
                                                    className="py-2"
                                                    onClick={() => handleRestoreCategory(category)}
                                                >
                                                    <i className="bi bi-arrow-clockwise text-dark"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                </div>
                            </td>
                        </tr>
                        {/* Subcategories */}
                        {expandedCategories[category.id] && category.childCategories.length > 0 && (
                            category.childCategories.map((sub) => (
                                <tr key={sub.id} className={`${sub.deleted ? 'text-muted' : ''}`}>
                                    <td className="ps-5">
                                        {sub.name}
                                        {sub.deleted && (
                                            <span className="ms-2 badge bg-secondary">{t('Archived')}</span>
                                        )}
                                    </td>
                                    <td className={sub?.deleted ? 'text-secondary' : 'text-dark'}>{sub.comment || "—"}</td>
                                    <td>
                                        <div className="position-relative d-flex justify-content-center align-items-center mx-auto" style={{ width: "30px", height: "30px" }}>
                                            {sub?.favicon ? (
                                                <img src={`${basePath}/${sub.favicon}`} alt={sub.name}/>
                                            ) : null}
                                            {sub.deleted ? <div className="position-absolute top-0 left-0 w-100 h-100 opacity-50"></div> : null}
                                        </div>
                                    </td>
                                    <td>{sub.numberOfItems || 0}</td>
                                    <td>
                                        <div className="d-flex gap-2 gap-2 justify-content-end align-items-center">
                                            {!sub.deleted && (
                                                <>
                                                    {/* Move Subcategory Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Move Category')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="py-2"
                                                            onClick={() => handleMoveCategory(sub)}
                                                        >
                                                            <i className="bi bi-box-arrow-right text-dark"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                    {/* Edit Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="py-2"
                                                            onClick={() => handleEditCategory(sub)}
                                                        >
                                                            <i className="bi bi-pencil text-dark"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                    {/* Archive Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Delete')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="py-2"
                                                            onClick={() => handleArchiveCategory(sub)}
                                                        >
                                                            <i className="bi bi-trash text-dark"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </>
                                            )}
                                            {sub.deleted && (
                                                <>
                                                    {/* Restore Button */}
                                                    <OverlayTrigger overlay={<Tooltip>{t('Restore')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="py-2"
                                                            onClick={() => handleRestoreCategory(sub)}
                                                        >
                                                            <i className="bi bi-arrow-clockwise text-dark"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </Table>
            {categories?.length ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}

             {/*Category Form Modal */}
            {showCategoryModal && (
                <CategoryFormModal
                    show={showCategoryModal}
                    handleClose={() => setShowCategoryModal(false)}
                    category={selectedCategory}
                    parentCategory={parentCategory}
                    onSave={() => {
                        refetch();
                        setShowCategoryModal(false);
                    }}
                />
            )}

            {showMoveCategoryModal && categoryToMove && (
                <MoveCategoryModal
                    show={showMoveCategoryModal}
                    handleClose={() => setShowMoveCategoryModal(false)}
                    categoryToMove={categoryToMove}
                    onMove={() => {
                        refetch();
                        setShowMoveCategoryModal(false);
                    }}
                 categories={[]}/>
            )}

            {showDeleteModal && categoryToDelete && (
                <DeleteCategoryModal
                    show={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    category={categoryToDelete}
                    onDelete={handleDeleteCategory}
                />
            )}
        </div>
    );
};

export default CategoriesTable;
