import { CSSObjectWithLabel } from 'react-select';

const customStyles = (error:any, touched:any, isMeMenu:boolean, isTitle:boolean, isMulti:boolean) => {
    return {
        control: (provided: CSSObjectWithLabel, {isFocused, isSelected}: {isFocused: Boolean, isSelected: Boolean}) => {
            const defaultBorderColor = isFocused ? '#ACA1DF' : '#DEDEDE';
            const borderColor = (touched && error) ? '#DC3545' : defaultBorderColor;
            return {
                ...provided,
                borderColor: borderColor,
                padding: "6px 0px",
                borderRadius: '4px',
                minHeight: '100%',
                boxShadow: isFocused ? '0 0 0 0.2rem rgba(111, 66, 193, 0.25)' : isSelected ? '0 0 0 0.2rem rgba(111, 66, 193, 0.25)' : 'none',
                '&:hover': {
                    borderColor: '#ACA1DF',
                },
                height: isMeMenu ? "45px" : "",
            }
        },

        valueContainer: (provided: CSSObjectWithLabel) => ({
            ...provided,
            bottom: isTitle ? "-6px" : ""

        }),

        option: (provided: CSSObjectWithLabel, {isSelected}: {isSelected: Boolean}) => ({
            ...provided,
            borderRadius: "4px",
            marginBottom: "8px",
            padding: "4px 12px",
            cursor: "pointer",
            backgroundColor: isSelected ? '#5842BE' : '#FFFFFF',
            color: isSelected ? '#FFFFFF' : '#1F3354',
            '&:hover': {
                backgroundColor: '#EEECF9',
                color: '#1F3354',
            },
        }),

        placeholder: (provided: CSSObjectWithLabel) => ({
            ...provided,
            fontSize: "14px",
            color: "#9D9D9D",
            margin: "0px 4px",
        }),

        input: (provided: CSSObjectWithLabel) => ({
            ...provided,
            fontSize: "14px",
            color: "#1F3354",
        }),

        dropdownIndicator: (provided: CSSObjectWithLabel, {selectProps}: any) => ({
            ...provided,
            transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
            transition: 'transform 0.2s ease',
            display: isMulti ? "none" : "flex",
        }),

        menu: (provided: CSSObjectWithLabel) => ({
            ...provided,
            marginTop: "4px",
            display: isMulti ? "none" : "block"
        }),

        menuList: (provided: CSSObjectWithLabel) => ({
            ...provided,
            padding: '8px 12px',
        }),

        indicatorSeparator: () => ({
            display: 'none',
        }),

        singleValue: (provided: CSSObjectWithLabel) => ({
            ...provided,
            fontSize: "14px",
        }),

        multiValue: (provided: CSSObjectWithLabel) => ({
            ...provided,
            backgroundColor: "#EEECF8",
            color:"#5842BE",
        }),

        multiValueLabel: (provided: CSSObjectWithLabel) => ({
            ...provided,
            color:"#5842BE",
            fontSize: "14px",
        }),

        multiValueRemove: (provided: CSSObjectWithLabel) => ({
            ...provided,
            ':hover': {
                backgroundColor: "#5842BE",
                color: '#FFFFFF',
            },
        }),

        clearIndicator: (provided: CSSObjectWithLabel) => ({
            ...provided,
            display: 'none',
        }),
    }
}
export default customStyles;