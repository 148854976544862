import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, OverlayTrigger, Tooltip, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {FiltersButton, Pagination, ReactSelect, RenderEmptyList, TableHeader} from '@services/ui-components';
import { useGetAdditivesTableMenuSupplementsQuery } from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import AdditiveGroupFormModal from './AdditiveGroupFormModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import emptyImage from "../../images/additives-empty-page.png";

interface AdditiveGroup {
    id: number;
    groupName: string;
    items: { name: string }[]; // Dishes associated with the additive group
    numberOfOption: number;
    isRequireOnOrder: boolean;
    maxCountOnOrder: number;
    deleted: boolean;
}

interface FilterState {
    name: string;
    categoryId: string;
}

const AdditivesTable: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [language, setLanguage] = useState<string>('en');
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState<FilterState>({ name: '', categoryId: '' });
    const [filter, setFilter] = useState<FilterState>({ name: '', categoryId: '' });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(20);
    // State for the modals
    const [showAdditiveGroupModal, setShowAdditiveGroupModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [editGroup, setEditGroup] = useState<AdditiveGroup | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteGroup, setDeleteGroup] = useState<AdditiveGroup | null>(null);

    // Toggle filters
    const toggleFilters = () => setShowFilters(!showFilters);

    // Handle language change (if needed)
    const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLanguage(e.target.value);
        // Additional logic to handle language change if necessary
    };

    // Fetch additive groups
    const { data, loading, error, refetch } = useGetAdditivesTableMenuSupplementsQuery({
        variables: {
            search: filter.name || null,
            id: filter.categoryId ? parseInt(filter.categoryId) : null,
            page: currentPage,
            itemsPerPage,
        },
    });

    if (loading) return <div>{t('Loading...')}</div>;
    if (error) return <div>{t('An error occurred:')} {error.message}</div>;

    const supplements = data?.user?.company?.menuSupplements?.supplements || [];

    const pageCount = parseInt(data?.user?.company?.menuSupplements?.pageCount || '0');
    const totalItems = data?.user?.company?.menuSupplements?.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    // Handle editing and deleting (archiving) additive groups
    const handleEditGroup = (group: AdditiveGroup) => {
        setEditGroup(group);
        setShowEditModal(true);
    };

    // Handle pagination
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        refetch();
    };

    // Handle filter apply and clear
    const handleApplyFilter = () => {
        setFilter(currentFilter);
        setCurrentPage(1);
        refetch();
    };

    const handleClearFilter = () => {
        setCurrentFilter({ name: '', categoryId: '' });
        setFilter({ name: '', categoryId: '' });
        setCurrentPage(1);
        refetch();
    };

    const handleArchiveGroup = (group: AdditiveGroup) => {
        setDeleteGroup(group);
        setShowDeleteModal(true);
    };

    const confirmDeleteGroup = () => {
        if (deleteGroup) {
            refetch();
        }
        setShowDeleteModal(false);
        setDeleteGroup(null);
    };

    const handleRestoreGroup = (group: AdditiveGroup) => {
        // Logic to restore the group
    };

    // Sort supplements to show deleted at the end
    const sortedSupplements = [...supplements].sort((a, b) => {
        if (a.deleted === b.deleted) return 0;
        return a.deleted ? 1 : -1;
    });

    const handleAddNewGroup = () => {
        setShowAdditiveGroupModal(true);
    };

    const isFilterNotEmpty = Object.values(filter).some(value => value !== '' && value !== null);

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleApplyFilter();
        }
    };

    return (
        <div className="mb-4">
            <TableHeader
                title={t('Additives and Options for dishes')}
                paths={[{ path: "/branches", label: t("Branches") }, { path: '/additives', label: t('Additives for dishes') }]}
                buttons={
                    <Row className="gx-3 justify-content-end mt-3 mt-lg-0">
                        <Col xs={12} sm={5} xxl={4} className="mb-3 mb-lg-0">
                            <div className="position-relative">
                                <span className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">{t("Language version")}</span>
                                <ReactSelect
                                    id="listOfDishes-language-field"
                                    value={{value: 'Українська (основна)', label: 'Українська (основна)'}}
                                    options={[]}
                                    placeholder={t("Type of branch")}
                                    onChange={() => {}}
                                    isSearchable={true}
                                    disabled={false}
                                    isMeMenu={true}
                                    isTitle={true}
                                />
                            </div>
                        </Col>
                        <Col xs={10} sm={5} xxl={4} className="mb-3 mb-lg-0">
                            <Button
                                variant="primary"
                                className="fw-normal w-100 rounded-1 fw-bold text-truncate"
                                onClick={handleAddNewGroup}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                {t('Create Group')}
                            </Button>
                        </Col>
                        <Col xs={2}>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters} classesText="d-none d-xxl-block"/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
            >
                {/* Filters */}
                {showFilters && (
                    <Row className="gx-4 pb-6">
                        <Col lg={8} xl={9}>
                            <Row className="gx-3">
                                <Col sm={6} className="mb-3 mb-lg-0">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control rounded-1"
                                            placeholder={t('Search by name')}
                                            value={currentFilter.name}
                                            onChange={(e) =>
                                                setCurrentFilter({ ...currentFilter, name: e.target.value })
                                            }
                                            onKeyDown={handleSearchKeyDown}
                                        />
                                        <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3"></i>
                                    </div>
                                </Col>
                                <Col sm={6} className="mb-3 mb-lg-0">
                                    {/*Replace on category picker*/}
                                    <Form.Group controlId="categoryFilter">
                                        <Form.Control
                                            as="select"
                                            value={currentFilter.categoryId}
                                            onChange={(e) =>
                                                setCurrentFilter({ ...currentFilter, categoryId: e.target.value })
                                            }
                                        >
                                            <option value="">{t('Category / Subcategory')}</option>
                                            {/* Replace with actual categories */}
                                            <option value="1">{t('Pizza')}</option>
                                            <option value="2">{t('Pasta')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} xl={3}>
                            <Row className="gx-3 h-100">
                                <Col sm={6} className="mb-2 mb-sm-0">
                                    <Button
                                        variant="outline-dark"
                                        className="border-grey w-100 h-100 fw-normal rounded-1"
                                        onClick={handleClearFilter}
                                    >
                                        {t('Clear')}
                                    </Button>
                                </Col>
                                <Col sm={6}>
                                    <Button
                                        variant="primary"
                                        className="w-100 h-100 rounded-1 fw-normal px-1"
                                        onClick={handleApplyFilter}
                                    >
                                        {t('Apply')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </TableHeader>
            {/* Additives Table */}
            <div className="table-responsive mb-4 scrollbar">
                {
                    sortedSupplements.length > 0 ? (
                        <Table>
                            <thead>
                            <tr className="fs-7">
                                <th className="text-nowrap ps-4">{t('Group Name')}</th>
                                <th className="table-thead-width">{t('Dishes')}</th>
                                <th className="text-nowrap">{t('Number of Options')}</th>
                                <th className="text-nowrap">{t('Choice when ordering')}
                                    <OverlayTrigger
                                        overlay={<Tooltip>{t('Obligatory or optional selection of additives/options when ordering a dish with this group of additives/options')}</Tooltip>}>
                                        <i className="bi bi-info-circle ms-2 fs-7 pt-1"></i>
                                    </OverlayTrigger>
                                </th>
                                <th className="text-nowrap">{t('Selectable options')}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                sortedSupplements.map((group: AdditiveGroup) => (
                                    <tr key={group.id} className={group.deleted ? 'text-secondary' : 'text-dark'}>
                                        <td className={`ps-4 ${group.deleted ? 'text-secondary' : 'text-dark'}`}>{group.groupName}</td>
                                        {/* Add tooltip like on the List of Dishes table*/}
                                        <td>{group.items}</td>
                                        <td className={group.deleted ? 'text-secondary' : 'text-dark'}>{group.numberOfOption}</td>
                                        <td>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    label={group.isRequireOnOrder ? t('Required') : t('Unrequited')}
                                                    id={`mandatory-switch-${group.id}`}
                                                    checked={group.isRequireOnOrder}
                                                    // disabled
                                                />
                                            </Form>
                                        </td>
                                        <td className={group.deleted ? 'text-secondary' : 'text-dark'}>{group.maxCountOnOrder}</td>
                                        <td>
                                            <div className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                                                {!group.deleted && (
                                                    <>
                                                        {/* Edit Button */}
                                                        <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                            <Button
                                                                variant="outline-secondary"
                                                                onClick={() => handleEditGroup(group)}
                                                                className="py-2 text-dark rounded-1"
                                                            >
                                                                <i className="bi bi-pencil"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                        {/* Archive Button */}
                                                        <OverlayTrigger overlay={<Tooltip>{t('Delete')}</Tooltip>}>
                                                            <Button
                                                                variant="outline-secondary"
                                                                onClick={() => handleArchiveGroup(group)}
                                                                className="py-2 text-dark rounded-1"
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </>
                                                )}
                                                {group.deleted && (
                                                    <>
                                                        {/* Restore Button */}
                                                        <span className="text-grey fs-7 px-3 py-2 fw-normal rounded-1 bg-additional-grey">{t('Archive')}</span>
                                                        <OverlayTrigger overlay={<Tooltip>{t('Restore')}</Tooltip>}>
                                                            <Button
                                                                variant="outline-secondary"
                                                                className="py-2"
                                                                onClick={() => handleRestoreGroup(group)}
                                                            >
                                                                <i className="bi bi-arrow-clockwise text-dark"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    ) : (
                        <RenderEmptyList
                            isFilterNotEmpty={isFilterNotEmpty}
                            imageSrc={emptyImage}
                            altText="Dishes"
                            emptyFilterTitle='No data found for the given values'
                            emptyListTitle="You don't have any additives yet. Create your first option/additive"
                            buttonName={
                                <span className="fw-bold">
                                    <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
                                    {t("Create Group")}
                                </span>
                            }
                            onClickButton={() => navigate("/branch/menu/create-item")}
                        />
                    )
                }

            </div>
            {/* Pagination */}
            {sortedSupplements.length > 0 ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}
            {showAdditiveGroupModal && (
                <AdditiveGroupFormModal
                    show={showAdditiveGroupModal}
                    handleClose={() => setShowAdditiveGroupModal(false)}
                    onSave={() => {
                        refetch();
                        setShowAdditiveGroupModal(false);
                    }}
                    language={language}
                    dishesData={[
                    { id: 1, name: 'Dish 1', category: 'Category 1', subcategory: 'Subcategory A' },
                    { id: 2, name: 'Dish 2', category: 'Category 1', subcategory: 'Subcategory A' },
                    { id: 3, name: 'Dish 3', category: 'Category 1', subcategory: 'Subcategory B' },
                    { id: 4, name: 'Dish 4', category: 'Category 2' },
                    { id: 5, name: 'Dish 5', category: 'Category 2' },
                        ]}
                />
            )}
            {showEditModal && editGroup && (
                <AdditiveGroupFormModal
                    show={showEditModal}
                    handleClose={() => {
                        setShowEditModal(false);
                        setEditGroup(null);
                    }}
                    onSave={() => {
                        refetch();
                        setShowEditModal(false);
                        setEditGroup(null);
                    }}
                    language={language}
                    dishesData={[
                        { id: 1, name: 'Dish 1', category: 'Category 1', subcategory: 'Subcategory A' },
                        { id: 2, name: 'Dish 2', category: 'Category 1', subcategory: 'Subcategory A' },
                        { id: 3, name: 'Dish 3', category: 'Category 1', subcategory: 'Subcategory B' },
                        { id: 4, name: 'Dish 4', category: 'Category 2' },
                        { id: 5, name: 'Dish 5', category: 'Category 2' },
                    ]}
                    existingGroup={editGroup}
                />
            )}
            {showDeleteModal && deleteGroup && (
                <ConfirmDeleteModal
                    show={showDeleteModal}
                    handleClose={() => {
                        setShowDeleteModal(false);
                        setDeleteGroup(null);
                    }}
                    onConfirm={confirmDeleteGroup}
                    groupName={deleteGroup.groupName}
                />
            )}
        </div>
    );
};

export default AdditivesTable;
