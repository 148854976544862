import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  TableHeader,
  Pagination,
  ReactSelect,
  FiltersButton,
  ProgressBar,
  BadgeAndButtonForArchive,
  RenderEmptyList
} from '@services/ui-components';
import {Row, Col, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom';
import {
  useDeleteMenuItemMutation,
  useGetMenuCategoriesQuery,
  useGetMenuItemsQuery
} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import ModalCloneMenuItem from './ModalCloneMenuItem';
import ModalDeleteMenuItem from './ModalDeleteMenuItem';
import {getSteps, IStep} from './constants';
import emptyImage from "../../images/dishes.png";
import {MenuItemProxy} from '@me-team/host/graphql/types';
import {ApolloError} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';

const MenuItems: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const steps: IStep[] = getSteps(t);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);
  const [filter, setFilter] = useState({
    search: '',
    menuCategoryId: null,
  });

  const [currentFilter, setCurrentFilter] = useState({
    search: '',
    menuCategoryId: null,
  });
  const [sortBy, setSortBy] = useState('name');
  const [order, setOrder] = useState('ASC');

  const {data: menuItemsData, loading, error, refetch: refetchMenuItems} = useGetMenuItemsQuery({
    variables: {
      itemsPerPage,
      page: currentPage,
      sortBy,
      order,
      search: filter.search ? filter.search : null,
      menuCategoryId: filter.menuCategoryId ? filter.menuCategoryId : null,
    },
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
    fetchPolicy: 'cache-and-network'
  });

  // State variables for cloning
  const [showCloneModal, setShowCloneModal] = useState<boolean>(false);
  const [currentDishId, setCurrentDishId] = useState<number>(null);

  const {data: categoriesData} = useGetMenuCategoriesQuery();

  // State variables for deleting
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<any>(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [deleteMenuItemMutation] = useDeleteMenuItemMutation();

  const handleCloneClick = (id: number) => {
    setCurrentDishId(id)
    setShowCloneModal(true);
  };

  const handleDeleteClick = (menuItem: any) => {
    setItemToDelete(menuItem);
    // WAIT FOR BACKEND VALUE MENU
    const isUsedInMenu = menuItem.isUsedInMenu;

    if (isUsedInMenu) {
      setDeleteModalMessage(
        t("Are you sure you want to delete this dish? The dish has links in the menu. It will be moved to the archive, and you will be able to restore it.")
      );
    } else {
      setDeleteModalMessage(
        t("Are you sure you want to delete this dish? It will be moved to the archive, and you will be able to restore it.")
      );
    }

    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!itemToDelete) return;

    try {
      await deleteMenuItemMutation({
        variables: {id: parseInt(itemToDelete)},
      });

      refetchMenuItems();

      setShowDeleteModal(false);
      setItemToDelete(null);
    } catch (error) {
      console.error('Error deleting menu item:', error);
    }
  };

  const handleApplyFilter = () => {
    setFilter(currentFilter);
    setCurrentPage(1);
  };

  const handleClearFilter = () => {
    setFilter({search: '', menuCategoryId: null});
    setCurrentFilter({search: '', menuCategoryId: null});
    setSortBy('name');
    setOrder('ASC');
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'}
  ];

  const handleEditMenuItem = (id: number) => {
    navigate(`/branch/menu/item/${id}`);
  };

  const menuItems = menuItemsData?.user?.company?.menuItems?.items?.map((item: MenuItemProxy) => ({
    id: item.id,
    name: item.name,
    categories: item.categories.map((category: any) => {
      const subcategories = category?.childCategories?.map((sub: any) => sub.name).join(', ');
      return `${category.name}${subcategories ? '/' + subcategories : ''}`;
    }).join(', '),
    price: item.portions.map((portion: any) => `${portion.price} грн`).join('/'),
    volume: item.portions.map((volume: any) => `${volume.volume} г`).join('/'),
    relatedMenuCount: item?.menus?.length,
    isArchive: item.deleted
  })) || [];
  const currentCompanyId = menuItemsData?.user?.company?.id;
  const pageCount = parseInt(menuItemsData?.user.company.menuItems.pageCount || '0');
  const totalItems = menuItemsData?.user.company.menuItems.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  const categoryOptions = categoriesData?.user.company.meMenuCategoriesPaginated.categories.flatMap((category) => {
    const options = [{value: category.id, label: category.name}];
    const subcategoryOptions = category?.childCategories?.map((sub) => ({
      value: sub.id,
      label: `${category.name} / ${sub.name}`,
    }));
    return [...options, ...subcategoryOptions];
  });

  const sortingOptions = [
    {value: {sortBy: 'name', order: 'ASC'}, label: t('Alphabetically (A-Z)')},
    {value: {sortBy: 'name', order: 'DESC'}, label: t('Alphabetically (Z-A)')},
    {value: {sortBy: 'price', order: 'ASC'}, label: t('From cheapest')},
    {value: {sortBy: 'price', order: 'DESC'}, label: t('From most expensive')},
  ];

  const isFilterNotEmpty = Object.values(filter).some(value => value !== '' && value !== null);

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleApplyFilter();
    }
  };

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="mb-4">
      <TableHeader
        title={t("List of dishes")}
        paths={[{path: "/branches", label: t("Branches")}, {
          path: "/branch/menu/items",
          label: t("List of dishes")
        }]}
        buttons={
          <Row className='gx-3 justify-content-end'>
            <Col sm={6} xl={3} xxl={3} className="mb-2 mb-xxl-0">
              <div className="position-relative">
                <span
                  className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">{t("Language version")}</span>
                <ReactSelect
                  id="listOfDishes-language-field"
                  value={{value: 'Українська (основна)', label: 'Українська (основна)'}}
                  options={[]}
                  placeholder={t("Type of branch")}
                  onChange={() => {
                  }}
                  isSearchable={true}
                  disabled={false}
                  isMeMenu={true}
                  isTitle={true}
                />
              </div>
            </Col>
            <Col sm={6} xl={4} xxl={3} className="mb-2 mb-xxl-0">
              <Button
                className="btn btn-linear-gradient border-0 fw-bold py-6 text-capitalize w-100"><i
                className='bi bi-book me-2'></i>{t('Create menu')}</Button>
            </Col>
            <Col xs={10} xl={3}>
              <Button onClick={() => navigate(`/branch/menu/create-item`)}
                      className="btn btn-primary fw-bold text-capitalize w-100"><i
                className='bi bi-plus-lg me-lg-2'></i> {t('Add dish')}</Button>
            </Col>
            <Col xs={2} xxl={2}>
              <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}
                             classesText="d-none d-xxl-block"/>
            </Col>
          </Row>
        }
        showFilters={showFilters}
        colSize={4}
        btnColSize={8}
      >
        <Row className='gx-3'>
          <Col lg={9}>
            <Row className='gx-3'>
              <Col lg={4}>
                <div className="position-relative mb-3 mb-lg-0">
                  <input
                    id="listOfDishes-search-field"
                    type="text"
                    className="form-control rounded-1 ps-6 pe-5 text-truncate"
                    placeholder={t("Search by name, description")}
                    value={currentFilter.search}
                    onChange={(e) => setCurrentFilter({...currentFilter, search: e.target.value})}
                    onKeyDown={handleSearchKeyDown}
                  />
                  <i
                    className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3'></i>
                </div>
              </Col>
              <Col lg={4}>
                <ReactSelect
                  id="listOfDishes-category-field"
                  value={categoryOptions?.find(option => option.value === currentFilter.menuCategoryId)}
                  options={categoryOptions}
                  placeholder={t("Category")}
                  onChange={(selectedOption) =>
                    setCurrentFilter({
                      ...currentFilter,
                      menuCategoryId: selectedOption ? selectedOption.value : null
                    })
                  }
                  isSearchable={true}
                  onKeyDown={handleSearchKeyDown}
                />
              </Col>
              <Col lg={4}>
                <div className="position-relative">
                  <span
                    className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">Sort</span>
                  <ReactSelect
                    id="listOfDishes-sort-field"
                    value={sortingOptions.find(option => option.value.sortBy === sortBy && option.value.order === order)}
                    options={sortingOptions}
                    placeholder={t("Sort by")}
                    onChange={(selectedOption) => {
                      setSortBy(selectedOption.value.sortBy);
                      setOrder(selectedOption.value.order);
                    }}
                    isSearchable={false}
                    isTitle={true}
                    onKeyDown={handleSearchKeyDown}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={3}>
            <Row className='gx-3 h-100'>
              <Col xs={6}>
                <Button variant="outline-dark" className="border-grey w-100 h-100 fw-normal"
                        onClick={handleClearFilter}
                >{t("clear")}</Button>
              </Col>
              <Col xs={6}>
                <Button variant="primary" className="btn btn-primary w-100 h-100 fw-normal px-1"
                        onClick={handleApplyFilter}
                >{t("apply")}</Button>
              </Col>

            </Row>
          </Col>
        </Row>
      </TableHeader>

      <ProgressBar currentStep={2} steps={steps}/>
      <div className="table-responsive mb-4">
        {menuItems.length > 0 ? (
          <table className="table mb-2">
            <thead>
            <tr className='fs-7'>
              <th className="col-1 text-center px-4">{t("photo")}</th>
              <th>{t("name")}</th>
              <th>{t("categoriesSubcategories")}</th>
              <th>{t("pricePrices")}</th>
              <th>{t("volume")}</th>
              <th>{t("menu")}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {
              menuItems.map((menuItem: any) => {
                const colorForArchive = menuItem?.isArchive ? 'text-secondary' : '';
                const categoryList = menuItem.categories.split(", ");
                const displayedCategories = categoryList.length > 2
                  ? categoryList.slice(0, 2).join("; ")
                  : menuItem.categories.split(", ").join("; ");
                const hiddenCategories = categoryList.length > 2 ? categoryList.slice(2).join("; ") : "";
                return (<tr key={menuItem.id} className="align-middle">
                    <td className="align-middle">
                      <div
                        className="d-flex justify-content-center align-items-center rounded-circle border border-secondary mx-auto img-size"
                        style={{opacity: menuItem?.isArchive ? '0.4' : ''}}
                      >
                        {/* TODO: which image default if images is not */}
                        <img src={menuItem?.image ? menuItem?.image : null}
                             alt={menuItem?.name}
                             className="rounded-circle mw-100 mh-100"/>
                      </div>
                    </td>
                    <td>
                                                       <span className={colorForArchive}>
                                                          {menuItem.name}
                                                       </span>
                    </td>
                    <td className={colorForArchive}>
                      {
                        hiddenCategories
                          ? <>
                            {displayedCategories};
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>{hiddenCategories}</Tooltip>}>
                              <span className="cursor-pointer">{' '}...</span>
                            </OverlayTrigger>
                          </>
                          : <span>{displayedCategories}</span>
                      }
                    </td>
                    <td>
                                          <span className={`${colorForArchive} text-nowrap`}>
                                            {menuItem?.price}
                                          </span>
                    </td>
                    <td>
                                          <span className={`${colorForArchive} text-nowrap`}>
                                            {menuItem.volume}
                                          </span>
                    </td>
                    <td>
                                            <span className={colorForArchive}>
                                              {menuItem?.relatedMenuCount}
                                            </span>
                    </td>
                    <td>
                      <div
                        className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                        {
                          !menuItem?.isArchive ? (
                              <>
                                <OverlayTrigger
                                  overlay={<Tooltip>{t('Clone')}</Tooltip>}>
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => handleCloneClick(menuItem?.id)}
                                    className="py-2 text-dark rounded-1"
                                  >
                                    <i className="bi bi-stickies"></i>
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => handleEditMenuItem(menuItem?.id)}
                                    className="p-2 fs-7 text-dark rounded-1"
                                  >
                                    <i className="bi bi-pencil px-1"></i>
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={<Tooltip>{t('Delete')}</Tooltip>}>
                                  <Button
                                    variant="outline-secondary"
                                    className="py-2 text-dark rounded-1"
                                    onClick={() => handleDeleteClick(menuItem?.id)}
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </Button>
                                </OverlayTrigger>
                              </>
                            )
                            :
                            <BadgeAndButtonForArchive
                              // hideButton={isEmployeeRole}
                              //  TODO когда будет готова страница восстановления архивного блюда поменять ссылку на нее
                              onButtonClick={() => navigate(`/branch/menu/item/${menuItem.id}`)}
                            />
                        }
                      </div>
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        ) : (
          <RenderEmptyList
            isFilterNotEmpty={isFilterNotEmpty}
            imageSrc={emptyImage}
            altText="Dishes"
            emptyFilterTitle='No data found for the given values'
            emptyListTitle="You don't have any dishes yet. Create your first dish"
            buttonName={
              <span className="fw-bold">
                                <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
                {t("Create dish")}
                            </span>
            }
            onClickButton={() => navigate("/branch/menu/create-item")}
          />
        )}
      </div>

      {menuItems.length > 0 ? (
        <div className="d-flex justify-content-between align-items-center">
          <Pagination
            t={t}
            pages={Number(pageCount)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            totalItems={totalItems}
          />
        </div>
      ) : null}

      <ModalCloneMenuItem
        showCloneModal={showCloneModal}
        setShowCloneModal={setShowCloneModal}
        currentDishId={currentDishId}
        refetchMenuItems={refetchMenuItems}
      />
      <ModalDeleteMenuItem
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteModalMessage={deleteModalMessage}
        handleConfirmDelete={handleConfirmDelete}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default MenuItems;
