import React, { useState } from 'react';
import {
    Table,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    DropdownButton,
    Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Pagination, TableHeader } from '@services/ui-components';

interface Menu {
    id: number;
    name: string;
    type: string;
    mainLanguage: string;
    languages: string[];
    status: 'In Development' | 'Active' | 'Inactive';
    categoryCount: number;
    dishCount: number;
    archived: boolean;
}

const MenuPage: React.FC = () => {
    const { t } = useTranslation();

    // Mock data
    const initialMenus: Menu[] = [
        {
            id: 1,
            name: 'Main Menu',
            type: 'For In-House Orders',
            mainLanguage: 'English',
            languages: ['English', 'Spanish'],
            status: 'Active',
            categoryCount: 5,
            dishCount: 25,
            archived: false,
        },
        {
            id: 2,
            name: 'Delivery Menu',
            type: 'For Delivery Orders',
            mainLanguage: 'English',
            languages: ['English'],
            status: 'In Development',
            categoryCount: 3,
            dishCount: 15,
            archived: false,
        },
        {
            id: 3,
            name: 'Archived Menu',
            type: 'For Pickup Orders',
            mainLanguage: 'English',
            languages: ['English', 'French'],
            status: 'Inactive',
            categoryCount: 4,
            dishCount: 20,
            archived: true,
        },
    ];

    // State variables
    const [menus, setMenus] = useState<Menu[]>(initialMenus);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [filterName, setFilterName] = useState<string>('');
    const [filterType, setFilterType] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;

    // State for modals
    const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<'add' | 'edit' | 'clone' | 'restore'>('add');
    const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    // Toggle filters
    const toggleFilters = () => setShowFilters(!showFilters);

    // Handle filters
    const applyFilters = () => {
        let filteredMenus = initialMenus;
        if (filterName) {
            filteredMenus = filteredMenus.filter((menu) =>
                menu.name.toLowerCase().includes(filterName.toLowerCase())
            );
        }
        if (filterType) {
            filteredMenus = filteredMenus.filter((menu) => menu.type === filterType);
        }
        setMenus(filteredMenus);
        setCurrentPage(1);
    };

    const clearFilters = () => {
        setFilterName('');
        setFilterType('');
        setMenus(initialMenus);
        setCurrentPage(1);
    };

    // Handle pagination
    const totalItems = menus.length;
    const pageCount = Math.ceil(totalItems / itemsPerPage);
    const paginatedMenus = menus.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handle actions
    const handleAddMenu = () => {
        setModalMode('add');
        setSelectedMenu(null);
        setShowAddEditModal(true);
    };

    const handleEditMenu = (menu: Menu) => {
        setModalMode('edit');
        setSelectedMenu(menu);
        setShowAddEditModal(true);
    };

    const handleCloneMenu = (menu: Menu) => {
        setModalMode('clone');
        setSelectedMenu(menu);
        setShowAddEditModal(true);
    };

    const handleDeleteMenu = (menu: Menu) => {
        setSelectedMenu(menu);
        setShowDeleteModal(true);
    };

    const handleRestoreMenu = (menu: Menu) => {
        // Restore the menu
        const updatedMenus: any = menus.map((m) =>
            m.id === menu.id ? { ...m, archived: false, status: 'Active' } : m
        );
        setMenus(updatedMenus);
    };

    const handleConfirmDelete = () => {
        if (selectedMenu) {
            // Archive the menu
            const updatedMenus: any = menus.map((m) =>
                m.id === selectedMenu.id ? { ...m, archived: true, status: 'Inactive' } : m
            );
            setMenus(updatedMenus);
            setShowDeleteModal(false);
            setSelectedMenu(null);
        }
    };

    const handleSaveMenu = (menuData: any) => {
        if (modalMode === 'add') {
            // Add new menu
            const newMenu: Menu = {
                id: menus.length + 1,
                name: menuData.name,
                type: menuData.type,
                mainLanguage: menuData.mainLanguage,
                languages: menuData.languages,
                status: 'In Development',
                categoryCount: 0,
                dishCount: 0,
                archived: false,
            };
            setMenus([...menus, newMenu]);
        } else if (modalMode === 'edit' && selectedMenu) {
            // Edit existing menu
            const updatedMenus = menus.map((m) =>
                m.id === selectedMenu.id ? { ...m, ...menuData } : m
            );
            setMenus(updatedMenus);
        } else if (modalMode === 'clone' && selectedMenu) {
            // Clone menu
            const clonedMenu: Menu = {
                id: menus.length + 1,
                name: menuData.name,
                type: menuData.type,
                mainLanguage: selectedMenu.mainLanguage,
                languages: menuData.languages,
                status: 'In Development',
                categoryCount: selectedMenu.categoryCount,
                dishCount: selectedMenu.dishCount,
                archived: false,
            };
            setMenus([...menus, clonedMenu]);
        } else if (modalMode === 'restore' && selectedMenu) {
            // Restore menu
            const updatedMenus: any = menus.map((m) =>
                m.id === selectedMenu.id ? { ...m, archived: false, status: 'Active' } : m
            );
            setMenus(updatedMenus);
        }
        setShowAddEditModal(false);
        setSelectedMenu(null);
    };

    return (
        <div className="mb-4">
            <TableHeader
                title={t('Menu List')}
                paths={[{ path: '/menus', label: t('Menus') }]}
                buttons={
                    <Row className="gx-3 justify-content-end">
                        <Col sm={6} lg={5} xxl={4}>
                            <Button
                                variant="primary"
                                className="fw-normal w-100 rounded-1"
                                onClick={handleAddMenu}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                {t('Add Menu')}
                            </Button>
                        </Col>
                        <Col lg={6}>
                            <Button
                                className="btn btn-additional-violet fw-normal border-primary text-primary w-100"
                                onClick={toggleFilters}
                            >
                                <i className="bi bi-funnel me-2"></i>
                                {t('Filters')}
                            </Button>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={7}
                btnColSize={5}
            >
                {/* Filters */}
                {showFilters && (
                    <Row className="gx-4 pb-6">
                        <Col lg={8} xl={9}>
                            <Row className="gx-3">
                                <Col md={6}>
                                    <div className="position-relative mb-3 mb-lg-0">
                                        <input
                                            type="text"
                                            className="form-control rounded-1"
                                            placeholder={t('Search by menu name')}
                                            value={filterName}
                                            onChange={(e) => setFilterName(e.target.value)}
                                        />
                                        <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3"></i>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="menuTypeFilter">
                                        <Form.Control
                                            as="select"
                                            value={filterType}
                                            onChange={(e) => setFilterType(e.target.value)}
                                        >
                                            <option value="">{t('Select Menu Type')}</option>
                                            <option value="For In-House Orders">{t('For In-House Orders')}</option>
                                            <option value="For Delivery Orders">{t('For Delivery Orders')}</option>
                                            <option value="For Pickup Orders">{t('For Pickup Orders')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} xl={3}>
                            <Row className="gx-3 h-100">
                                <Col sm={6} className="mb-2 mb-sm-0">
                                    <Button
                                        variant="outline-dark"
                                        className="border-grey w-100 h-100 fw-normal rounded-1"
                                        onClick={clearFilters}
                                    >
                                        {t('Clear')}
                                    </Button>
                                </Col>
                                <Col sm={6}>
                                    <Button
                                        variant="primary"
                                        className="w-100 h-100 rounded-1 fw-normal px-2"
                                        onClick={applyFilters}
                                    >
                                        {t('Apply')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </TableHeader>
            {/* Menus Table */}
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th>{t('Menu Name')}</th>
                    <th>{t('Menu Type')}</th>
                    <th>{t('Main Language')}</th>
                    <th>{t('Language Versions')}</th>
                    <th>{t('Status')}</th>
                    <th>{t('Categories')}</th>
                    <th>{t('Dishes')}</th>
                    <th>{t('Actions')}</th>
                </tr>
                </thead>
                <tbody>
                {paginatedMenus.length === 0 ? (
                    <tr>
                        <td colSpan={8}>{t('No menus found.')}</td>
                    </tr>
                ) : (
                    paginatedMenus.map((menu: Menu) => (
                        <tr key={menu.id} className={menu.archived ? 'text-muted' : ''}>
                            <td>
                                {menu.name}
                                {!menu.archived && (
                                    <Button
                                        variant="link"
                                        className="p-0 ms-2"
                                        onClick={() => handleEditMenu(menu)}
                                    >
                                        <i className="bi bi-pencil-square" aria-hidden="true"></i>
                                    </Button>
                                )}
                            </td>
                            <td>{menu.type}</td>
                            <td>{menu.mainLanguage}</td>
                            <td>{menu.languages.join(', ')}</td>
                            <td>{menu.status}</td>
                            <td>{menu.categoryCount}</td>
                            <td>{menu.dishCount}</td>
                            <td>
                                <div className="d-flex gap-2">
                                    {!menu.archived && (
                                        <>
                                            <Button
                                                type="button"
                                                variant="outline-primary"
                                                onClick={() => console.log('Edit Menu Items')}
                                                title={t('Edit Menu')}
                                            >
                                                {t('Edit Menu')}
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="outline-secondary"
                                                onClick={() => console.log('View Menu')}
                                                title={t('View')}
                                            >
                                                {t('View')}
                                            </Button>
                                            <DropdownButton
                                                id={`dropdown-${menu.id}`}
                                                title={<i className="bi bi-three-dots"></i>}
                                                variant="outline-secondary"
                                                align="end"
                                            >
                                                <Dropdown.Item onClick={() => handleCloneMenu(menu)}>
                                                    {t('Clone Menu')}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => console.log('Generate QR')}>
                                                    {t('Generate QR')}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDeleteMenu(menu)}>
                                                    {t('Delete Menu')}
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </>
                                    )}
                                    {menu.archived && (
                                        <>
                                            <Button
                                                type="button"
                                                variant="outline-success"
                                                onClick={() => handleRestoreMenu(menu)}
                                            >
                                                {t('Restore')}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
            {/* Pagination */}
            {pageCount > 1 && (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={pageCount}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                        firstItemIndex={(currentPage - 1) * itemsPerPage + 1}
                        lastItemIndex={Math.min(currentPage * itemsPerPage, totalItems)}
                        totalItems={totalItems}
                    />
                </div>
            )}
            {/* Add/Edit Menu Modal */}
            {showAddEditModal && (
                <AddEditMenuModal
                    show={showAddEditModal}
                    handleClose={() => {
                        setShowAddEditModal(false);
                        setSelectedMenu(null);
                    }}
                    onSave={handleSaveMenu}
                    mode={modalMode}
                    menu={selectedMenu}
                />
            )}
            {/* Delete Confirmation Modal */}
            {showDeleteModal && selectedMenu && (
                <ConfirmDeleteModal
                    show={showDeleteModal}
                    handleClose={() => {
                        setShowDeleteModal(false);
                        setSelectedMenu(null);
                    }}
                    onConfirm={handleConfirmDelete}
                    menuName={selectedMenu.name}
                />
            )}
        </div>
    );
};

// Add/Edit Menu Modal Component
interface AddEditMenuModalProps {
    show: boolean;
    handleClose: () => void;
    onSave: (menuData: any) => void;
    mode: 'add' | 'edit' | 'clone' | 'restore';
    menu: Menu | null;
}

const AddEditMenuModal: React.FC<AddEditMenuModalProps> = ({
                                                               show,
                                                               handleClose,
                                                               onSave,
                                                               mode,
                                                               menu,
                                                           }) => {
    const { t } = useTranslation();

    // Form state
    const [menuName, setMenuName] = useState<string>(menu ? menu.name : '');
    const [menuType, setMenuType] = useState<string>(menu ? menu.type : '');
    const [languages, setLanguages] = useState<string[]>(menu ? menu.languages : ['English']);
    const mainLanguage = 'English'; // Assume main language is English

    // Title and button text based on mode
    let title = '';
    let saveButtonText = '';

    switch (mode) {
        case 'add':
            title = t('Create Menu');
            saveButtonText = t('Create');
            break;
        case 'edit':
            title = t('Edit Menu');
            saveButtonText = t('Save');
            break;
        case 'clone':
            title = t(`Clone Menu "${menu?.name}"`);
            saveButtonText = t('Clone');
            // Pre-fill name with "Copy - Original Name"
            if (!menuName.startsWith('Copy - ')) {
                setMenuName(`Copy - ${menu?.name}`);
            }
            break;
        case 'restore':
            title = t('Restore Menu');
            saveButtonText = t('Restore');
            break;
        default:
            break;
    }

    // Handle form submission
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const menuData = {
            name: menuName,
            type: menuType,
            mainLanguage,
            languages,
        };
        onSave(menuData);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Menu Name */}
                    <Form.Group controlId="menuName">
                        <Form.Label>
                            {t('Menu Name')} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={menuName}
                            onChange={(e) => setMenuName(e.target.value)}
                            required
                            maxLength={50}
                            placeholder={t('Enter menu name')}
                            disabled={mode === 'restore'}
                        />
                        <Form.Text className="text-muted">{`${menuName.length}/50`}</Form.Text>
                    </Form.Group>
                    {/* Menu Type */}
                    <Form.Group controlId="menuType">
                        <Form.Label>
                            {t('Menu Type')} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            value={menuType}
                            onChange={(e) => setMenuType(e.target.value)}
                            required
                            disabled={mode === 'edit' || mode === 'restore'}
                        >
                            <option value="">{t('Select Menu Type')}</option>
                            <option value="For In-House Orders">{t('For In-House Orders')}</option>
                            <option value="For Delivery Orders">{t('For Delivery Orders')}</option>
                            <option value="For Pickup Orders">{t('For Pickup Orders')}</option>
                        </Form.Control>
                    </Form.Group>
                    {/* Language Versions */}
                    <Form.Group controlId="languages">
                        <Form.Label>
                            {t('Language Versions')} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            value={languages}
                            onChange={(e) =>
                                setLanguages(Array.from(e.target.selectedOptions, (option) => option.value))
                            }
                            required
                            disabled={mode === 'restore'}
                        >
                            <option value="English">{t('English')}</option>
                            <option value="Spanish">{t('Spanish')}</option>
                            <option value="French">{t('French')}</option>
                            {/* Add other languages as needed */}
                        </Form.Control>
                        <Form.Text className="text-muted">
                            {t('Default language versions from company settings.')}
                        </Form.Text>
                    </Form.Group>
                    {/* Main Language */}
                    <Form.Group controlId="mainLanguage">
                        <Form.Label>
                            {t('Main Language')} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control type="text" value={mainLanguage} disabled />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Cancel')}
                    </Button>
                    <Button variant="primary" type="submit">
                        {saveButtonText}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

// Confirm Delete Modal
interface ConfirmDeleteModalProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    menuName: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
                                                                   show,
                                                                   handleClose,
                                                                   onConfirm,
                                                                   menuName,
                                                               }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('Delete Menu')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('Are you sure you want to delete the menu "{{menuName}}"?', { menuName })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    {t('Delete')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MenuPage;
